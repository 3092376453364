@import '../css/reset.css';
@import './fonts.scss';

$md: 1250px;
$sm: 991px;
$xs: 768px;

html{
  overflow-x: hidden;
}

body{
  font-family: 'Avenir Next Cyr';
  font-size: 18px;
  padding: 0;
  margin: 0;

  @media (max-width: 1800px) {
    font-size: 15px;
  }
  @media (max-width: 1500px) {
    font-size: 13px;
  }

  @media (max-width: $md) {
    font-size: 16px;    
  }

  @media (max-width: $sm) {
    font-size: 14px;    
  }
}

.wrapper{
  max-width: 1920px;
  margin: 0 auto;
}
.container{
  width: 1770px;
  margin: 0 auto;

  @media (max-width: 1800px) {
    width: 1470px;
  }
  @media (max-width: 1500px) {
    width: 1220px;
  }
  @media (max-width: $md) {
    width: 960px;
  }
  @media (max-width: $sm) {
    max-width: 100%;
    padding: 0 20px;
  }
}
.input{
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  outline: none;
  padding: 0.55em 1em 0.55em;
  margin-bottom: 1.3em;

  &-error{
    border-bottom: 1px solid #ee2340;
  }
}
.button{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  border: 3px solid #ee2340;;
  background: transparent;
  text-decoration: none;
  height: 70px;
  font-size: 1.33em;
  color: #ee2340;

  &:hover{
    background: #ee2340;
    color: #fff;
  }

  @media (max-width: 1500px) {
    height: 50px;
  }

  &-red{
    background: #ee2340;
    color: #fff;

    &:hover{
      background: transparent;
      color: #ee2340;
    }
  }

  &-white{
    border-color: #fff;
    color: #fff;

    &:hover{
      border-color: #fff;
      background: transparent;
      color: #fff;
    }
  }
}

.red{
  color: #ee2340;
}
.darkred{
  color: #be172f;
}

.title{
  margin: 0;
  font-size: 5.333em;
  text-align: center;
  font-weight: 600;
  line-height: 1.23;
  
  @media (max-width: $md) {
    font-size: 4em;
  }
  @media (max-width: $xs) {
    font-size: 3.333em;
  }
  @media (max-width: 360px) {
    font-size: 2.88em;
  }
}

.header{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  padding-top: 1em;
}

.section-1{
  padding: 7.7em 0 5em;
  background: #fff url(~/images/section-1__line.svg) no-repeat;
  background-position: 50% 53%;
  background-size: contain;

  @media (max-width: 1800px) {
    background-position: 50% 48%;
  }

  @media (max-width: 1500px) {
    background-position: 50% 56%;
  }
  @media (max-width: $md) {
    background-position: 50% 53%;
  }
  @media (max-width: $xs) {
    position: relative;
    background-position: 50% 38%;
  }

  &__content{
    width: 83.333%;
    margin: 0 auto;

    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__title{
    font-size: 8em;
    line-height: .88;
    font-weight: 600;
    margin: 0;
    @media (max-width: $md) {
      font-size: 6em;
    }
    @media (max-width: $xs) {
      font-size: 3.75em;
    }
  }

  &__subtitle{
    margin-top: 0.68em;
    font-size: 3.55em;
    font-weight: 600;
    line-height: 0.8;
    @media (max-width: $md) {
      font-size: 2.66em;
    }
    @media (max-width: $xs) {
      font-size: 2em;
    }
  }

  &__text{
    margin-top: 5.125em;
    font-size: 1.333em;
    line-height: 1.6;
    width: 88%;
    @media (max-width: $md) {
      width: 100%;
    }
  }

  &__row{
    display: flex;
  }

  &__col{
    width: 50%;

    &-leftside{
      @media (max-width: $md) {
        width: 60%;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }

    &-rightside{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      @media (max-width: 1500px) {
        justify-content: center;
      }
      @media (max-width: $md) {
        justify-content: flex-end;
        width: 40%;
      }
      @media (max-width: $xs) {
        width: 0;
      }
    }
  }

  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.666em;
    width: 88%;
    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xs) {
      flex-direction: column;
    }

    & .button{
      width: 47%;
      @media (max-width: $xs) {
        width: 100%;
        margin-bottom: 1em;
      }
    }
  }

  &__heart{
    transform: scale(1);
    animation: heartbeat 1s infinite;

    
    @media (max-width: 1500px) {
      max-height: 40em;
    }
    @media (max-width: $md) {
      max-height: 30em;
    }
    @media (max-width: $xs) {
      position: absolute;
      top: 20%;
      right: 1em;
      width: 17vw;
    }
    @media (max-width: 400px) {
      top: 30%;
    }
  }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( 1 )
  }
   40%
  {
    transform: scale( 1.1 )
  }
}

@keyframes heartBeatCenter
{
  0%
  {
    transform: scale( 1 ) translate(-50%, -50%)
  }
   40%
  {
    transform: scale( 1.1 ) translate(-50%, -50%)
  }
}

.section-2{
  position: relative;
  background: #f0f0f0;
  padding: 55px 0 80px;

  &__content{
    width: 83.333%;
    margin: 0 auto;

    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__title{
    color: #ee2340;
    font-weight: 600;
    line-height: 1.3;
    font-size: 2.66em;
    max-width: 10.9em;
  }

  &__subtitle{
    margin-top: 1.5em;
    font-size: 1.333em;
  }

  &__promo{
    position: absolute;
    top: 55px;
    right: 1em;
    width: 51em;

    @media (max-width: $md) {
      width: 40%;
    }

    @media (max-width: $sm) {
      top: 65px;
    }
    @media (max-width: $xs) {
      top: 0;
      transform: translateY(-50%);
      width: 100%;
      right: inherit;
      left: 0;
      mix-blend-mode: multiply;
    }
  }

  &__form{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: $sm) {
      flex-direction: column;
    }

    & .button{
      width: 315px;
      @media (max-width: 1500px) {
        width: 260px;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }

    &_col{
      &-rightside{
        @media (max-width: $sm) {
          margin-top: 1.5em;
        }
        @media (max-width: $xs) {
          width: 100%;
        }
      }
      &-leftside{
        width: calc(100% - 380px);
        @media (max-width: 1500px) {
          width: calc(100% - 320px);
        }
        @media (max-width: $sm) {
          width: 100%;
        }
      }
    }
  }

  &__input_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $xs) {
      flex-direction: column;
    }
    & .input{
      width: calc(50% - 15px);
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  & .input-last{
    margin-bottom: 0;
  }
}

.section-3{
  background: #313137;
  padding: 80px 0;
  color: #fff;

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: $md) {
      flex-wrap: wrap;
    }

    @media (max-width: $xs) {
      flex-direction: column;      
    }
  }

  &__item{
    width: 20%;

    
    @media (max-width: 1500px) {
      width: 22.5%;
    }

    @media (max-width: $md) {
      width: 45%;
      margin-bottom: 2em;
    }
    @media (max-width: $xs) {
      width: 100%;   
    }

    &_title{
      margin-bottom: 0.55em;
      text-transform: uppercase;
      font-size: 2.333em;
      line-height: 1.222;
      font-weight: bold;
      background: url(~/images/section-3__title.jpg) no-repeat center center;
      background-size: cover;
      background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
      font-weight: 700;
    }

    &_subtitle{
      font-size: 1.111em;
      line-height: 1.25;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 1em;
    }
    &_text{
      line-height: 1.225;
    }
  }
}

.section-4{
  background: #fff;
  padding: 100px 0 60px;

  @media (max-width: $md) {
    padding: 60px 0;
  }

  &__items{
    position: relative;
    margin-top: 3.333em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media (max-width: $xs) {
      flex-direction: column;      
    }
    
    &::after{
      content: '';
      display: block;
      width: 26%;

      
      @media (max-width: 1500px) {
        width: 29%;
      }
      @media (max-width: $md) {
        width: 45%;    
      }
      @media (max-width: $xs) {
        display: none;     
      }
    }
  }

  &__item{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #000;
    margin-bottom: 40px;
    width: 26%;
    
    @media (max-width: 1500px) {
      width: 29%;
    }
    @media (max-width: $md) {
      width: 45%;
    }
    @media (max-width: $xs) {
      width: 100%; 
    }

    &:hover{
      & .section-4__item_title{
        color: #ee2340;
      }
    }
    &_title{
      font-size: 1.33em;
      text-transform: uppercase;
      margin-bottom: 15px;
      line-height: 1.22;
      font-weight: 600;
    }
    &_text{
      font-size: 1.333em;
      line-height: 1.222;
    }

    &_col{

      &-leftside{
        width: 4.444em;

        & .section-4__icon{
          width: calc(100% - 12px);
        }
      }

      &-rightside{
        width: calc(100% - 4.444em);
      }
    }
  }
}

.section-5{
  position: relative;
  overflow: hidden;
  background-size: cover;
  color: #fff;
  background: #313137;

  &__content{
    position: relative;
    z-index: 2;
    width: 24em;

    @media (max-width: $md) {
      width: 50%;
    }

    @media (max-width: $xs) {
      width: calc(100% - 40px);
    }
  }

  &__title{
    font-weight: 600;
    font-size: 5.333em;
    color: #bd1136;
    line-height: 1;
    @media (max-width: $xs) {
      font-size: 4em;
    }
  }

  &__subtitle{
    font-size: 1.555em;
    line-height: 1.3;
    margin-top: .75em;
  }

  &__text{
    margin-top: 1.111em;
    line-height: 1.3;
  }

  &__row{
    display: flex;
    flex-direction: row;
    max-width: 1920px;
    margin: 0 auto;
    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  &__col{
    &-leftside{
      position: relative;
      padding-top: 5.8333em;
      padding-bottom: 4.1666em;
      width: 39.0625%;
      padding-right: 5.111em;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: $md) {
        width: 100%;
        overflow: hidden;
        padding-right: 0;
        justify-content: center;
        padding-bottom: 5em;
      }

      &::before{
        content: '';
        position: absolute;
        top: -6%;
        left: 5em;
        z-index: 1;
        background: url(~/images/section-5__logo.svg) no-repeat center center;
        background-size: cover;
        width: 26.333em;
        height: 52.333em;
      }
    }

    &-rightside{
      position: relative;
      display: flex;
      width: 60.9375%;
      background: url(~/images/section-5__bg.jpg) no-repeat center center;
      background-size: cover;
      padding-left: 7.3889em;
      @media (max-width: $md) {
        padding-top: 2em;
        width: 100%;
      }

      @media (max-width: $xs) {
        padding-top: 6em;
        padding-bottom: 4em;
        padding-left: 0;
      }
    }
  }

  &__list{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.3em;
    border-top: 3px solid #ee2340;

    &_item{
      list-style: none;
      margin-right: 1.111em;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__buttons{
    margin-top: 2.222em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & .button{
      width: 304px;

      @media (max-width: 1500px) {
        width: 260px;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  &__social{
    
    &_link{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 2.444em;
      height: 2.444em;
      text-decoration: none;

      &:hover{
        background: #ee2340;
      }
    }

    &_icon{
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }

  &__items{
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: $xs) {
      flex-direction: column;
    }
  }

  &__item{
    width: 8em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 3.5em;

    @media (max-width: $xs) {
      margin-right: 0;
      width: 100%;
      height: auto;
      flex-direction: row-reverse;
      align-items: center;
    }

    &:last-child{
      margin-right: 0;
    }

    &-1 .section-5__item_text{
      height: 27.777em;
      @media (max-width: $xs) {
        height: auto;
        width: 80%;
      }

    }

    &-2 .section-5__item_text{
      height: 15em;
      @media (max-width: $xs) {
        height: auto;
        width: 60%;
      }
    }

    &-3 .section-5__item_text{
      height: 23.0555em;
      @media (max-width: $xs) {
        height: auto;
        width: 70%;
      }
    }

    
    &_title{
      color: #ee2340;
      font-size: 3.555em;
      line-height: 1.3;
      font-weight: bold;

      @media (max-width: $xs) {
        font-size: 2em;
      }
    }
    &_text{
      margin-top: 0.88em;
      color: #fff;
      border-top: 3px solid #ee2340;
      padding: 1.111em 0.555em 1.111em;
      background: linear-gradient(180deg, rgba(30, 35, 54, 0) 0%, rgba(180, 1, 39, 0.53) 100%);
      @media (max-width: $xs) {
        border-top: none;
        border-right: 3px solid #ee2340;
        margin-right: 0.88em;
        margin-top: 0;
        margin-bottom: 1em;
        width: 95%;
        background: linear-gradient(-90deg, rgba(30, 35, 54, 0) 0%, rgba(180, 1, 39, 0.53) 100%);
      }
    }
  }

  &__digital{
    position: absolute;
    top: 0%;
    right: 5em;
    z-index: 2;
    display: block;
    height: 100%;
    transition: transform .1s ease;
    transform-origin: top;

    @media (max-width: $xs) {
      top: -10.5em;
      right: 0em;
      height: 33%;
    }
  }
  &__generation{
    position: absolute;
    top: 0%;
    right: 6em;
    z-index: 1;
    display: block;
    height: 88%;
    @media (max-width: $xs) {
      top: -7.5em;
      right: 3em;
      height: 29%;
    }
  }


}

.section-6{
  background: #e5e5e5;
  padding: 100px 0;

  @media (max-width: $md) {
    padding: 60px 0;
  }

  @media (max-width: $xs) {
    width: 100%;
    overflow: hidden;

    & .container{
      width: 100%;
      padding: 0;
    }
  }

  &__items{
    margin-top: 3.333em;
    &_row{
      display: flex;
      flex-direction: row;
      @media (max-width: $md) {
        flex-direction: column;
      }
    }

    &_col{
      width: 50%;

      @media (max-width: $md) {
        width: 100%;
      }
    }
  }

  &__item{
    position: relative;
    padding: 3em 0 0 2.5em;
    height: 50%;

    @media (max-width: $md) {
      height: 29em;
    }
    
    @media (max-width: $xs) {
      padding: 1.5em;
      font-size: 16px;
    }

    &_title{
      color: #fff;
      font-size: 2em;
      line-height: 1.222;

      @media (max-width: $xs) {
        font-size: 1.5em;
      }
    }

    &_logo{
      position: absolute;

      @media (max-width: $xs) {
        position: relative;
      }
    }

    &_img{
      display: block;
      position: absolute;

      @media (max-width: $xs) {
        display: none;
      }
    }

    &:hover .section-6__item_hidden{
      opacity: 1;

      &__content{
        opacity: 1;
        transform: translateY(0);
      }
    }

    &-1{
      background: linear-gradient(103.4deg, #0F6BA7 1.75%, #2885C2 39.76%, #0F6BA7 98.67%);

      &::before{
        content: '';
        position: absolute;
        right: 2.33em;
        top: 2.25em;
        background: url(~/images/section-6__item-1_tiktok.svg) no-repeat center center;
        background-size: contain;
        width: 2.15em;
        height: 2.33em;
      }


      & .section-6__item_hidden{
        background: #0F6ba7;
      }

      & .section-6__item_logo{
        width: 20.7222em;
        right: 4.5em;
        bottom: 3.33em;

        @media (max-width: $xs) {
          right: inherit;
          bottom: inherit;
          width: 50%;
        }
      }

      & .section-6__item_img{
        width: 20em;
        left: 1.15em;
        bottom: 0;
      }
    }

    &-2{
      background: #EE2340;
      
      @media (max-width: $xs) {
        height: 32em;
      }

      & .section-6__item_hidden{
        background: #ee2340;
      }

      & .section-6__item_logo{
        width: 18.888em;
        left: 8.5em;
        bottom: 8em;
        
        @media (max-width: $xs) {
          left: inherit;
          bottom: inherit;
          width: 50%;
        }
      }

      & .section-6__item_img{
        width: 20em;
        right: 0;
        bottom: 0;
      }
    }

    &-3{
      background: linear-gradient(145.8deg, #C5F0FF 11.02%, #A1E5FC 98.99%);
      height: 50em;
      
      @media (max-width: $md) {
        height: 29em;
      }
      
      @media (max-width: $md) {
        height: 35em;
      }

      & .section-6__item_hidden{
        background: #A3E5FC;
      }

      & .section-6__item_logo{
        width: 11.222em;
        right: 2.333em;
        bottom: 3.2em;
        @media (max-width: $xs) {
          right: inherit;
          bottom: inherit;
          width: 50%;
        }
      }

      & .section-6__item_img{
        width: 50em;
        right: 0;
        bottom: 0;

        @media (max-width: $md) {
          height: 100%;
          width: auto;
        }
      }
    }
    
    &-4{
      background: url(~/images/section-6__item-4.jpg) no-repeat center center;
      background-size: cover;
      height: 50em;

      @media (max-width: $xs) {
        height: 34em;
      }

      & .section-6__item_hidden{
        background: #16CAD5;
      }

      & .section-6__item_logo{
        width: 19.111em;
        left: 3.333em;
        bottom: 3.5em;

        @media (max-width: $xs) {
          left: inherit;
          bottom: inherit;
          width: 50%;
        }
      }
    }
    
    &-5{
      background: #797983;
      
      @media (max-width: $xs) {
        height: 32em;
      }

      & .section-6__item_hidden{
        background: #242E48;
      }

      & .section-6__item_logo{
        width: 18.17em;
        left: 5.5em;
        bottom: 7.5em;

        @media (max-width: $xs) {
          left: inherit;
          bottom: inherit;
          width: 50%;
        }
      }

      & .section-6__item_img{
        width: 25em;
        right: 0;
        bottom: 0;
      }
    }
    
    &-6{
      background: linear-gradient(106.64deg, #0E9BB6 -6.23%, rgba(77, 190, 211, 0.74) 31.89%, #0A839A 101.59%);
      & .section-6__item_hidden{
        background: #0E9BB6;
      }

      & .section-6__item_logo{
        width: 21.111em;
        left: 3em;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: $xs) {
          left: inherit;
          top: inherit;
          width: 50%;
        }
      }

      & .section-6__item_img{
        height: 100%;
        right: 0;
        bottom: 0;
      }
    }

    &_shown{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    
    &_hidden{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      color: #fff;
      transition: opacity 0.2s ease;
      padding: 2.25em 3em 0 3em;

      
      @media (max-width: $xs) {
        padding: 1.5em;
      }

      &__content{
        opacity: 0;
        transform: translatex(3em);
        transition: transform .45s cubic-bezier(.64,.01,.15,1.16), opacity .2s ease .1s;
      }

      &__title{
        font-size: 2em;
        font-weight: 700;
        line-height: 1.222;
        margin-bottom: 0.5556em;

        @media (max-width: $xs) {
          font-size: 1.5em;
        }
      }

      &__subtitle{
        display: block;
        font-weight: 700;
      }

      &__row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        @media (max-width: $xs) {
          flex-direction: column;
        }
      }

      &__col{
        width: 45%;
        @media (max-width: $xs) {
          width: 100%;
        }
      }

      &__res{
        margin-top: 1.111em;
      }

      &__list{
        margin-top: 0.8333em;

        &_item{
          position: relative;
          margin-bottom: 0.8333em;
          padding-left: 1.85em;
          line-height: 1.222;

          &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 0.2em;
            background: url(~/images/section-6__check.svg) no-repeat left center;
            background-size: contain;
            width: 1em;
            height: .9em;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }

      & .button{
        width: 306px;

        @media (max-width: 1500px) {
          width: 260px;
        }
        &::after{
          content: '';
          position: relative;
          top: 2px;
          background: url(~/images/section-6__arrow.svg) no-repeat center center;
          background-size: contain;
          width: 1.5em;
          height: 0.5em;
          margin-left: 0.5em;
          transition: .3s transform ease-in-out;
        }

        &:hover{
          &::after{
            transform: translateX(.5em);
          }
        }
      }

      &__buttons{
        margin-top: 0.5em;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

}

.section-7{
  background: #f0f0f0;
  padding: 80px 0;

  &__items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__item{
    width: 16.666%;
    display: flex;
    align-items: center;

    @media (max-width: $xs) {
      width: 45%;
      margin-bottom: 1.5em;
      max-height: 6em;
    }
  }

}

.section-8{
  background: #fff;
  padding: 100px 0;
  
  @media (max-width: $md) {
    padding: 60px 0;
  }

  &__row{
    display: flex;
    flex-direction: row;
    margin-top: 3.333em;

    @media (max-width: $md) {
      flex-wrap: wrap;
    }
  }

  &__col{
    width: 16.666%;
    @media (max-width: $md) {
      width: 33.333%;
    }
    @media (max-width: $xs) {
      width: 100%;
    }

    &-big{
      width: 33.333%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: $md) {
        width: 66.666%;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  &__item{
    width: 100%;

    &-red{
      background: #be172f;
      color: #fff;
    }
    &-big{
      background: #be172f;
      color: #fff;
      height: 100%;
      @media (max-width: $xs) {
        padding: 1.5em;
      }
    }
    &_img{
      display: block;
      width: 100%;
    }

    &_count{
      font-size: 22.222em;
      font-weight: 600;
      text-align: center;
      line-height: 1;

      @media (max-width: $xs) {
        font-size: 13em;
      }
    }

    &_subtitle{
      font-size: 2em;
      line-height: 1;
      @media (max-width: $xs) {
        font-size: 1.5em;
      }
    }

    &-3{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &-7{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50%;
      
      @media (max-width: $xs) {
        height: auto;
      }
    }
    &_text{
      padding: 1.222em;
      font-weight: 600;
      line-height: 1.222;

      & p{
        margin-bottom: 1em;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

.section-9{
  background: #fff;
  padding: 0 0 100px;

  
  @media (max-width: $md) {
    padding-bottom: 60px;
  }

  &__slider{
    margin-top: 3.333em;
    padding: 3em 0;
    overflow: hidden;
  }

  & .swiper-slide{
    transform: scale(.7);
  }
  & .swiper-wrapper{
    padding-bottom: 2em;
  }

  &__item.swiper-slide-next{
    transform: scale(1);
  }

  &__item{
    transition: transform .2s ease;
  }
}

.section-10{
  background: #f0f0f0;
  padding: 80px 0;

  &__content{
    width: 83.333%;
    margin: 0 auto;

    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__title{
    font-size: 3.555em;
    font-weight: 600;
    line-height: .882;
    margin: 0 0 0.625em;
    width: 80%;
    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $md) {
      font-size: 2.33em;
    }
  }

  &__subtitle{
    margin-top: .66em;
    font-size: 2em;
    margin-bottom: 0.8em;
    line-height: 1.3;
    width: 80%;
    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $md) {
      font-size: 1.5em;
    }
  }

  &__text{
    line-height: 1.3;
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $md) {
      justify-content: space-between;
    }

    @media (max-width: $xs) {
      flex-direction: column;;
    }
  }

  &__col{

    &-leftside{
      width: 43%;
      @media (max-width: $md) {
        width: 48%;
      }
    @media (max-width: $xs) {
        width: 100%;
        margin-bottom: 2em;
      }
    }
    &-rightside{
      width: 43%;

      @media (max-width: $md) {
        width: 48%;
      }
    @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  &__form{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    padding: 2.222em 3.55em;

    @media (max-width: $xs) {
      padding: 1.8em 2.5em;
    }
  }

  & .button{
    width: 306px;

    @media (max-width: 1500px) {
      width: 270px;

      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }

  &__politic{
    margin-top: 1em;
  }
}

.section-11{
  position: relative;
  background: #ccc;
  padding: 80px 0 0;
  height: 480px;
  overflow: hidden;

  @media (max-width: $xs) {
    height: 70vh;
    padding-top: 50px;
  }
  
  &__content{
    position: relative;
    z-index: 5;
    background: #fff;
    padding: 2em;
    max-width: 422px;
    padding: 2em 2.5em;
  }

  &__text{
    font-size: 1.111em;
    line-height: 1.25;
    margin-bottom: 1em;
  }

  &__link{
    font-size: 1.333em;
    line-height: 1.3;
    color: #BE172F;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  &__map{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@keyframes promoRotate
{
  0%
  {
    transform: rotate( 0deg );
  }
   100%
  {
    transform: rotate( 360deg );
  }
}

@keyframes coffeFoggy
{
  0%
  {
    opacity: 0;
    transform: translateX(-50%) translateY(25%) scale(.9);
  }
   50%
  {
    opacity: 1;
    transform: translateX(-50%) translateY(-10%) scale(1);
  }
  85%
  {
    opacity: 0;
    transform: translateX(-50%) translateY(-25%) scale(1.05);
  }
  100%
  {
    opacity: 0;
    transform: translateX(-50%) translateY(25%) scale(.9);
  }
}

.breakfast-1{
  position: relative;
  padding: 11em 0 5em;
  color: #fff;
  background: #a92525;
  overflow: hidden;
  
  @media (max-width: $xs) {
    position: relative;
    background: #000;
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(2.32deg, #000000 2.99%, rgba(0, 0, 0, 0.549976) 14.51%, rgba(0, 0, 0, 0) 33.02%), linear-gradient(180.86deg, #000000 0.39%, rgba(0, 0, 0, 0.518227) 17.22%, rgba(0, 0, 0, 0) 35.33%), linear-gradient(270deg, #000000 -0.53%, rgba(0, 0, 0, 0.66) 11%, rgba(0, 0, 0, 0) 23.81%), linear-gradient(89.98deg, #000000 53.03%, rgba(0, 0, 0, 0) 94.12%);
    z-index: 2;

    @media (max-width: $xs) {
      display: none;
    }
  }

  &__content{
    width: 83.333%;
    margin: 0 auto;

    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__title{
    font-size: 4em;
    line-height: 1.12;
    font-weight: 600;
    margin: 0;
    @media (max-width: $md) {
      font-size: 3em;
    }
  }

  &__text{
    margin-top: 1.888em;
    font-size: 1.333em;
    line-height: 1.6;
    width: 88%;
    @media (max-width: $md) {
      width: 100%;
    }
  }

  &__row{
    display: flex;
  }

  &__col{
    width: 50%;

    &-leftside{
      position: relative;
      z-index: 3;
      @media (max-width: $md) {
        width: 60%;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }

    &-rightside{
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      @media (max-width: 1500px) {
        justify-content: center;
      }
      @media (max-width: $md) {
        justify-content: flex-end;
        width: 40%;
      }
      @media (max-width: $xs) {
        position: inherit;
        width: 0;
      }
    }
  }

  &__promo{
    position: absolute;
    left: 100%;
    bottom: 5em;
    width: 16.3em;
    height: 16.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $xs) {
      position: relative;
      bottom: inherit;
      left: inherit;
      margin: 1.5em auto 0;
    }

    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(~/images/breakfast-1__promo.svg) no-repeat center center;
      background-size: contain;
      animation: promoRotate 4s infinite;
    }
  }

  &__date{
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.666em;
    line-height: 1.6;
    color: #000;
    width: 66%;
    height: 66%;
  }

  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.666em;
    width: 88%;
    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xs) {
      flex-direction: column;
    }

    & .button{
      width: 47%;
      @media (max-width: $xs) {
        width: 100%;
        margin-bottom: 1em;
      }
    }
  }

  &__btn-arrow{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: transparent;
    text-decoration: none;
    height: 70px;
    font-size: 1.33em;
    color: #ee2340;
    
    @media (max-width: 1500px) {
      height: 50px;
    }
  
    &:hover::after{
      transform: translateX(.66em);
    }
  
    &::after{
      content: '';
      position: relative;
      top: 2px;
      background: url(~/images/breakfast-1__arrow.svg) no-repeat center center;
      background-size: contain;
      width: 1.5em;
      height: 0.5em;
      margin-left: 0.5em;
      transition: .3s transform ease-in-out;
    }
  }

  &__heart{
    max-width: 60%;
    transform: scale(1);
    animation: heartbeat 1s infinite;

    
    @media (max-width: 1500px) {
      max-height: 40em;
    }
    @media (max-width: $md) {
      max-height: 30em;
    }
    @media (max-width: $xs) {
      position: absolute;
      top: 1em;
      right: 1em;
      height: 5em;
    }
  }

  &__img{
    &-2{
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 60%;
      max-width: 100%;

      @media (max-width: $sm) {
        display: none;
      }
    }
  }
}

.breakfast-2{
  background: #fff;
  padding: 5.5555em 0;

  &__title{
    font-weight: 600;
    font-size: 3.5555em;
    text-align: center;
    line-height: 1.3;

    @media (max-width: $xs) {
      font-size: 2.5em;
    }
  }
  
  &__items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.2222em;

    @media (max-width: $xs) {
      flex-direction: column;
    }
  }

  &__item{
    width: 30%;

    @media (max-width: $xs) {
      margin-bottom: 2em;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &_img{
      width: 4.4444em;
      margin-bottom: 0.9em;
    }
    &_title{
      font-weight: 600;
      font-size: 2em;
      line-height: 1.3;
      @media (max-width: $xs) {
        font-size: 1.66em;
      }
    }
    &_text{
      margin-top: 1.2em;
      line-height: 1.66;
    }
  }

  &__timeline{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4.444em auto 0;
    width: 70.5%;

    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xs) {
      flex-direction: column;
      margin: 3.5em auto 0;
    }

    &::before{
      content: '';
      position: absolute;
      top: 3.3333em;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 9em);
      height: 2px;
      background: rgba(238, 35, 64, 0.5);
      z-index: 1;
      @media (max-width: $xs) {
        top: 50%;
        width: 2px;
        height: calc(100% - 9em);
      }
    }

    &_item{
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: $xs) {
        margin-bottom: 5em;    
        background: #fff;
        position: relative;
        z-index: 2;
        padding-bottom: 5px;


        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    &_circle{
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 100%;
      width: 6.6666em;
      height: 6.6666em;
      background: #ee2340;
      border: 1.1111em solid #fff;

      @media (max-width: $xs) {
        margin: 0 auto;
      }
    }

    &__img{
      height: 1.94444em;
    }

    &_title{
      font-size: 1.3333em;
      text-align: center;
    }
  }
  
  &__row{
    margin-top: 2.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $xs) {
      flex-direction: column;
    }
  }

  &__col{
    &-leftside{
      position: relative;
      display: flex;
      width: 47.2%;
      @media (max-width: $md) {
        width: 35%;
      }
      @media (max-width: $xs) {
        width: 100%;
        margin-bottom: 2em;
        padding-top: 5em;
      }
    }
    &-rightside{
      width: 52.8%;
      @media (max-width: $md) {
        width: 65%;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  &__fogg{
    position: absolute;
    top: 0;
    left: 50%;
    width: 16%;
    animation: coffeFoggy 2.5s linear 1.5s infinite;

    @media (max-width: $xs) {
      top: inherit;
      bottom: 50%;
      bottom: 60%;
    }
  }
  &__cup{
    position: absolute;
    bottom: -5.555em;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: $md) {
      bottom: inherit;
      top: 5.5em;
    }
    @media (max-width: $xs) {
      position: relative;
      top: inherit;
      left: inherit;
      transform: translateX(0);
    }
  }

  &__shedule{
    margin-top: 4.444em;
    border-top: 2px solid #EE2340;
    border-bottom: 2px solid #EE2340;
    padding: 2.222em 0 2.777em;

    @media (max-width: $xs) {
      margin-top: 2em;
    }

    &_title{
      font-weight: 600;
      font-size: 2em;
      line-height: 1.3;
    }
    
    &_list{
      margin-top: 2.222em;
      &_item{
        line-height: 1.3;
        margin-bottom: 1.111em;

        &:last-child{
          margin-bottom: 0;
        }

        @media (max-width: $xs) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &_date{
      font-size: 1.333em;
      font-weight: 600;
      color: #ee2340;
      margin-right: 2.08333em;
    }

    &_text{
      margin-top: 1.66em;
      font-size: 1.333em;
      line-height: 1.3;
    }
  }
}

.breakfast-3{
  background: #313137;
  padding: 5.5555em 0;
  color: #fff;

  &__title{
    margin-bottom: 0.625em;
    font-size: 3.5555em;
    line-height: 1.3;
    font-weight: 600;

    & .red{
      display: block;
    }

    @media (max-width: $xs) {
      font-size: 2.5em;
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $md) {
      flex-direction: column-reverse;
    }
  }

  &__col{
    &-leftside{
      width: 50%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
    &-rightside{
      width: 40%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
  }

  &__text{
    font-size: 1.333em;
    line-height: 1.3;
  }
  
  & p.breakfast-3__text{
    margin-bottom: 1.5em;
    @media (max-width: $md) {
      margin-bottom: 1.5em;
    }
  }

  &__items{
    display: flex;
    flex-direction: column;
  }

  &__item{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ccc;

    &:last-child{
      border-bottom: none;
    }
  }

  &__number{
    font-weight: bold;
    font-size: 6.666em;
    line-height: 1.3;
    color: #ee2340;
    margin-right: 0.55833em;

    @media (max-width: $xs) {
      margin-right: 20px;
    }
  }
}

.breakfast-4{
  background: #fff;
  padding: 5.5555em 0;

  &__title{
    margin-bottom: 0.625em;
    font-size: 3.5555em;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: $xs) {
      font-size: 2.5em;
    }
  }

  &__subtitle{
    margin-bottom: 0.41666em;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.3;
  }

  &__img{
    width: 26.833em;
  }
  
  &__text{
    margin-bottom: 1.25em;
    font-size: 1.333em;
    line-height: 1.3em;
  }

  &__list{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    border-top: 2px solid #be172f;
    width: 100%;

    &_link{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      text-decoration: none;

      &-inst svg{
        stroke: #ee2340;
      }
      &-vk svg{
        fill: #ee2340;
      }

      &:hover{
        background: #ee2340;
      }
      
      &-inst:hover svg{
        stroke: #fff;
      }
      &-vk:hover svg{
        fill: #fff;
      }
    }
  }

  &__promo{
    position: relative;
    background: #313137;
    padding: 3.66em 4.444em 4.222em;
    width: 83%;
    margin: 6.89em auto 0;
    color: #fff;

    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xs) {
      padding: 50px 30px;
      margin: 4em auto 0;
    }

    &::before{
      content: '';
      position: absolute;
      z-index: 1;
      left: 15px;
      top: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      background: transparent;
      border: 1px solid #fff;
    }

    &_row{
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $sm) {
        flex-direction: column;
      }
    }

    &_col{
      &-leftside{
        width: 30%;
        @media (max-width: $md) {
          width: 40%;
        }
        @media (max-width: $sm) {
          width: 100%;
        }
      }
      &-rightside{
        @media (max-width: $md) {
          width: 60%;
        }
        @media (max-width: $sm) {
          width: 100%;
        }
      }
    }

    &_title{
      font-weight: 600;
      font-size: 2em;
      line-height: 1.1;
      .red{
        display: block;
      }
      @media (max-width: $sm) {
        text-align: center;
      }
    }

    &_items{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $xs) {
        flex-direction: column;
      }
    }

    &_item{
      margin-right: 2.777em;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: $md) {
        flex-direction: column;
        text-align: center;
      }
      @media (max-width: $sm) {
        width: 33%;
        margin-top: 2em;
        margin-right: 0;
      }
      @media (max-width: $xs) {
        width: 100%;
      }

      &:last-child{
        margin-right: 0;
      }
    }

    &_num{
      font-weight: 700;
      font-size: 3.555em;
      line-height: 1.3;
      color: #ee2340;
    }
    &_text{
      margin-left: 0.8333em;
      font-weight: 600;
      font-size: 1.333em;
      line-height: 1.1;
      @media (max-width: $md) {
        margin-left: 0;
      }
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  &__col{
    &-leftside{
      width: 33.9%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
    &-rightside{
      width: 66%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
  }

  &__photos{
    width: 83%;
    margin: 7.222em auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xs) {
      flex-direction: column;
    }

    &-leftside{
      margin-right: 1.666em;
      width: 39%;
      @media (max-width: $xs) {
        width: 100%;
        margin-right: 0;
      }
    }

    &-rightside{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 60%;
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  &__photo{
    margin-bottom: 1.666em;
    &-4{
      width: 100%;
    }
    &-5,
    &-6{
      width: calc(50% - 0.833em);
      @media (max-width: $xs) {
        width: 100%;
      }
    }
  }

  
}

.breakfast-5{
  overflow: hidden;
  background: #313137;
  color: #fff;
  

  &__title{
    font-weight: 600;
    font-size: 2.666em;
    line-height: 1.3;
    width: 90%;

    @media (max-width: $sm) {
      width: 100%;
    }
    @media (max-width: $xs) {
      font-size: 2em;
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  &__col{
    &-leftside{
      width: 61%;
      padding: 5.5555em 0;

      @media (max-width: 1500px) {
        width: 60%;
      }
      @media (max-width: $md) {
        width: 100%;
      }
    }

    &-rightside{
      position: relative;
      width: 33%;
      @media (max-width: $md) {
        width: 0;
      }
    }
  }

  &__dg{
    position: absolute;
    z-index: 1;
    top: -2em;
    height: calc(100% + 4em);
    right: 20%;
  }

  &__digital_generation{
    position: absolute;
    z-index: 2;
    top: 3em;
    left: 0;
  }

  &__services{
    margin-top: 2em;
    padding-left: 8.2778em;

    @media (max-width: $sm) {
      padding-left: 4em;
    }
    @media (max-width: $xs) {
      padding-left: 0;
    }

    &_title{
      font-weight: 600;
      font-size: 1.333em;
      line-height: 1.3;
      color: #ee2340;
      @media (max-width: $xs) {
        margin-bottom: 1em;
      }
    }
    &_row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @media (max-width: $xs) {
        flex-direction: column;
      }
    }
  }

  &__links{
    margin-top: 1.39em;
    margin-right: 5em;
    @media (max-width: $xs) {
      margin-right: 0;
      margin-top: 0;
    }
    
    &:last-child{
      margin-right: 0;
    }
    

    &_item{
      margin-bottom: 5px;
    }
  }

  &__link{
    position: relative;
    display: inline-block;
    color: #fff;
    line-height: 1.3;
    font-weight: 600;
    text-decoration: none;
    
    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 1px;
      background: #ee2340;
      transform: scaleX(0);
      transform-origin: left;
      transition: all .2s ease-in-out;
    }

    &:hover{
      color: #ee2340;

      &::after{
        transform: scaleX(1);
      }
    }
  }
  
}

.breakfast-6{
  background: #fff;
  padding: 5.5555em 0;
  

  &__title{
    font-weight: 600;
    font-size: 3.5555em;
    line-height: 1.3;
  }
  
  &__slider{
    margin-top: 2.555em;
  }

  &__item{
    position: relative;
    padding-top: 4.2222em;

    &::before{
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      height: 2.777em;
      width: 2.11em;
      background: url(~/images/breakfast-6__quotes.svg) no-repeat center center;
      background-size: contain;
    }
  }

  &__items{
    padding-bottom: 60px;
  }

  & .review{
    line-height: 1.3;

    &__person{
      margin-top: 1.111em;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__photo{
      width: 4.4444em;
      margin-right: 1.2222em;
    }

    &__name{
      font-weight: 600;
    }
  }
}

.breakfast-7{
  background: #313137;
  padding: 5.5555em 0;
  color: #fff;

  & .section-10__subtitle,
  & .section-10__title{
    width: 100%;
  }

  & .section-10__text{
    font-size: 1.333em;
  }
  
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px;
}
.swiper-pagination-bullet{
  width: 30px;
  height: 8px;
  border-radius: 0;
  background: #e0e0e0;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background: #ee2340;
}

.politic{
  font-size: 0.8333em;
  line-height: 1.3;
  font-weight: 300;

  &__link{
    color: #ee2340;
    text-decoration: none;
  }
}
.section-popup{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
  display: none;

  @media (max-width: $xs) {
    padding: 0 20px;
  }

  &--active{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popup{
  position: relative;
  background: #fff;
  display: none;
  transform: translateY(3em);
  opacity: 0;
  transition: all .2s ease-in-out;
  max-width: 640px;
  width: 100%;
  padding: 2.75em 3.9em;

  @media (max-width: $xs) {
    max-width: 100%;
    width: 100%;
    padding: 2.5em 1.5em;
  }

  &-active{
    display: block;
    opacity: 1;
    transform: translateY(0);
  }

  &-thanks{
    overflow: hidden;
    padding: 7em 2em;
    @media (max-width: $xs) {
      padding: 7em 2em;
    }
    & .popup__container{
      z-index: 2;
    }

    & .popup__subtitle{
      margin-top: 1.111em;
    }
  }

  &__heart{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8em;
    animation: heartBeatCenter 1s infinite;

    &_img{
      width: 100%;
    }
  }

  &__close{
    position: absolute;
    top: 1.1em;
    right: 1.1em;
    width: 0.95em;
    height: 0.95em;
    background: url(~/images/popup__close.svg) no-repeat center center;
    background-size: contain;
    cursor: pointer;
  }

  &__title{
    font-size: 2em;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  &__subtitle{
    margin-top: 0.555em;
    margin-bottom: 1em;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
  }

  &__politic{
    margin-top: .666em;
  }
}

.footer{
  background: #fff;
  padding: 30px 0 38px;

  &__text{
    color: #4f4f4f;
    font-size: 0.8889em;
    text-align: center;
  }
}